import React, {useContext} from 'react';
import {StaticImage} from 'gatsby-plugin-image';

import {FooterContextKeys, FooterProps} from '../../context/footer/footer-context';
import {Countries, RegistrationLinkByCountry} from '../../context/header/header-context';
import CountryContext from '../../context/country/country-context';
import {LinkComponent} from '../../components';
import LinkItem from '../../pages-content/contact-us/link-item';
import Image from "../../components/image/image";

import './style.scss';

const Footer = ({
    [FooterContextKeys.home]: home,
    [FooterContextKeys.careers]: careers,
    [FooterContextKeys.contact]: contact,
    [FooterContextKeys.about]: about,
    [FooterContextKeys.partner]: partner,
    [FooterContextKeys.news]: news,
    [FooterContextKeys.privacy]: privacy,
    [FooterContextKeys.rights]: rights,
    [FooterContextKeys.sign_up]: sign_up,
    [FooterContextKeys.terms]: terms,
    [FooterContextKeys.socials]: socials,
    [FooterContextKeys.address]: address,
    [FooterContextKeys.contactInfo]: contactInfo
}: FooterProps) => {
    const {countryCode} = useContext(CountryContext);

    return (
        <footer className="main-footer">
            <div className="container main-footer__content-container">
                <div className="row mb-3">
                    <div className="col-xl-2">
                        <StaticImage
                            src="../../images/collectius-logo-white.svg"
                            alt="Collectius logo"
                            width={138}
                            height={27}
                        />
                    </div>
                    <div className="col-6 d-xl-none my-5">
                        <div
                            className="main-footer__text"
                            dangerouslySetInnerHTML={{__html: rights}}
                        />
                    </div>
                    <div
                        className="col-xl-2 offset-xl-1 d-flex flex-column mb-2 mb-xl-0"
                    >
                        <LinkComponent
                            className="main-footer__link"
                            to="/"
                            aria-label={home}
                        >
                            {home}
                        </LinkComponent>
                        <LinkComponent
                            className="main-footer__link"
                            to="/terms-and-conditions"
                            aria-label={terms}
                        >
                            {terms}
                        </LinkComponent>
                        <LinkComponent
                            className="main-footer__link"
                            to="/privacy-policy"
                            aria-label={privacy}
                        >
                            {privacy}
                        </LinkComponent>
                        {countryCode === Countries.in && (
                            <>
                                <LinkComponent
                                    className="main-footer__link"
                                    to="/gro-summary"
                                    aria-label="GRO Policy"
                                >
                                    GRO Policy
                                </LinkComponent>
                                <LinkComponent
                                    className="main-footer__link"
                                    to="/grievance-redressal-policy"
                                    aria-label="Grievance Redressal Policy"
                                >
                                    Grievance Redressal Policy
                                </LinkComponent>
                                <LinkComponent
                                    className="main-footer__link"
                                    to="/refund-and-cancel-policy"
                                    aria-label="Refund and Cancellation Policy"
                                >
                                    Refund and Cancellation Policy
                                </LinkComponent>
                            </>
                        )}

                    </div>
                    <div
                        className="col-xl-2 d-flex flex-column mb-2 mb-xl-0"
                    >
                        <LinkComponent
                            className="main-footer__link"
                            to="/about"
                            aria-label={about}
                        >
                            {about}
                        </LinkComponent>
                        <LinkComponent
                            className="main-footer__link"
                            to="/careers"
                            aria-label={careers}
                        >
                            {careers}
                        </LinkComponent>
                        <LinkComponent
                            className="main-footer__link"
                            to="/partner-form"
                            aria-label={partner}
                        >
                            {partner}
                        </LinkComponent>
                    </div>
                    <div
                        className="col-xl-3 d-flex flex-column mb-2 mb-xl-0"
                    >
                        {/* <a
                            className="main-footer__link-title"
                            to="#"
                        >
                            Pay off debt
                        </a> */}
                        <a
                            className="main-footer__link"
                            href={RegistrationLinkByCountry[countryCode]}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={sign_up}
                        >
                            {sign_up}
                        </a>
                        <LinkComponent
                            className="main-footer__link"
                            to="/contact-us"
                            aria-label={contact}
                        >
                            {contact}
                        </LinkComponent>
                    </div>
                    <div
                        className="col-xl-1 d-flex flex-column mb-2 mb-xl-5 mb-xl-0"
                    >
                        <LinkComponent
                            className="main-footer__link-title"
                            to="/news"
                            aria-label={news}
                        >
                            {news}
                        </LinkComponent>
                    </div>
                    <div className="col-xl-2">
                        {socials.map(({icon, valueByCountry}, index) => (
                            <a
                                key={index}
                                className="main-footer__link-icon"
                                href={valueByCountry[countryCode]}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label={icon}
                            >
                                <Image
                                    fileName={icon}
                                    rawPath={`/icons/${icon}`}
                                    width={24}
                                    height={24}
                                />
                            </a>
                        ))}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-xl-6">
                        <div
                            className="main-footer__text"
                            dangerouslySetInnerHTML={{__html: address.valueByCountry[countryCode]}}
                        />
                    </div>
                </div>
                <div className="row mb-xl-5">
                    <div className="col-xl-3">
                        <div className="d-flex flex-column">
                            {contactInfo.valueItems.map((valueItemData, index) => (
                                <LinkItem
                                    key={index}
                                    className="main-footer__link"
                                    aria-label={valueItemData.label}
                                    {...valueItemData}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row d-none d-xl-flex">
                    <div className="col-2">
                        <div
                            className="main-footer__text"
                            dangerouslySetInnerHTML={{__html: rights}}
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
