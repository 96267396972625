// Singapore - sg - default
// Philippines - ph
// Indonesia - id
// Malaysia - my
// Thailand - th
// Vietnam - vn
// India - in

import {countryCodeType} from "../country/country-context";

export const LanguagesKeys = Object.freeze({
    en: 'en',
    id: 'id',
    th: 'th',
    my: 'my',
    ph: 'ph',
    vn: 'vn',
    in: 'in'
});
export const LanguagesLabelsByKey = Object.freeze({
    [LanguagesKeys.en]: 'English',
    [LanguagesKeys.id]: 'Bahasa',
    [LanguagesKeys.th]: 'Thai',
    [LanguagesKeys.my]: 'Malay',
    [LanguagesKeys.ph]: 'Filipino',
    [LanguagesKeys.vn]: 'Vietnamese',
});
export const LanguageFileNamesByKey = Object.freeze({
    [LanguagesKeys.en]: 'english',
    [LanguagesKeys.id]: 'indonesian',
    [LanguagesKeys.th]: 'thai',
    [LanguagesKeys.my]: 'malay',
    [LanguagesKeys.ph]: 'filipino',
    [LanguagesKeys.vn]: 'vietnamese'
});

export const Countries = Object.freeze({
    sg: 'sg',
    ph: 'ph',
    id: 'id',
    my: 'my',
    th: 'th',
    vn: 'vn',
    in: 'in'
});

export const CountryNamesByCode = Object.freeze({
    [Countries.sg]: 'singapore',
    [Countries.ph]: 'philippines',
    [Countries.id]: 'indonesia',
    [Countries.th]: 'thailand',
    [Countries.my]: 'malaysia',
    [Countries.vn]: 'vietnam',
    [Countries.in]: 'india'
});
export const CountryCodesByName = Object.freeze({
    [CountryNamesByCode[Countries.sg]]: Countries.sg,
    [CountryNamesByCode[Countries.ph]]: Countries.ph,
    [CountryNamesByCode[Countries.id]]: Countries.id,
    [CountryNamesByCode[Countries.th]]: Countries.th,
    [CountryNamesByCode[Countries.my]]: Countries.my,
    [CountryNamesByCode[Countries.vn]]: Countries.vn,
    [CountryNamesByCode[Countries.in]]: Countries.in,
});

export const CountryFlagsByKey = Object.freeze({
    [Countries.sg]: 'singapore',
    [Countries.ph]: 'philippines',
    [Countries.id]: 'indonesia',
    [Countries.th]: 'thailand',
    [Countries.my]: 'malaysia',
    [Countries.vn]: 'vietnam',
    [Countries.in]: 'india'
});

export const defaultCountry = Countries.sg;

export const HeaderContextKeys = Object.freeze({
    countries_dropdown: 'countries-dropdown',
    about_dropdown: 'about-dropdown',
    media_dropdown: 'media-dropdown',
    home_link: 'home-link',
    pay_off_dropdown: 'pay-off-dropdown',
});

export const LanguagesByCountry = Object.freeze({
    [Countries.sg]: [LanguagesKeys.en],
    [Countries.ph]: [LanguagesKeys.en, LanguagesKeys.ph],
    [Countries.id]: [LanguagesKeys.en, LanguagesKeys.id],
    [Countries.my]: [LanguagesKeys.en, LanguagesKeys.my],
    [Countries.th]: [LanguagesKeys.en, LanguagesKeys.th],
    [Countries.vn]: [LanguagesKeys.en, LanguagesKeys.vn],
    [Countries.in]: [LanguagesKeys.en],
});

export const RegistrationLinkByCountry = Object.freeze({
    [Countries.sg]: 'https://portalsg.collectius.com/welcome/',
    [Countries.ph]: 'https://portalph.collectius.com/welcome/',
    [Countries.id]: 'https://collectius-id.powerappsportals.com/welcome/',
    [Countries.my]: 'https://portalmy.collectius.com/welcome/',
    [Countries.th]: 'https://collectius-th.powerappsportals.com/welcome/',
    [Countries.vn]: 'https://portalvn.collectius.com/welcome/',
    [Countries.in]: 'https://portal.collectius.in/welcome/',
});

export const HeaderContextDefaultValue = Object.freeze({
    [HeaderContextKeys.countries_dropdown]: [],
    [HeaderContextKeys.about_dropdown]: {},
    [HeaderContextKeys.media_dropdown]: {},
    [HeaderContextKeys.home_link]: {},
    [HeaderContextKeys.pay_off_dropdown]: {},
});

export interface HeaderProps {
    [HeaderContextKeys.countries_dropdown]: { [key: string]: any },
    [HeaderContextKeys.about_dropdown]: { [key: string]: any },
    [HeaderContextKeys.media_dropdown]: { [key: string]: any },
    [HeaderContextKeys.home_link]: { [key: string]: any },
    [HeaderContextKeys.pay_off_dropdown]: { [key: string]: any },
}

export const countryDisabledChatBot: Array<countryCodeType> = [Countries.ph, Countries.th, Countries.id, Countries.vn, Countries.my, Countries.sg, Countries.in];

export const defaultScrollOption = {ignoreCancelEvents: false, duration: 0};
